<template>
    <div>
        <h1 class="card-title text-white">{{$t('smartholder.title')}}</h1>
        <div v-if="this.$root.account" class="row">
            <div class="col-md-6">
                <div class="w-100">
                    <span class="text-left">{{$t('smartholder.staking_amount')}}</span>
                    <span class="text-info small" @click="setMax"> max {{balanceSth - stakeFee}}</span> STH
                    <span v-if="!this.$root.isMobile" @click="setMax" class="float-right cursor-pointer">{{$t('smartholder.sth_available')}} <span
                            class="text-primary">{{balanceSth}}</span> STH</span>
                </div>
                <b-form-group>
                    <b-input-group>
                        <b-form-input class="form-control" id="input_amount"
                                      placeholder="0.0"
                                      type="text"
                                      v-model="amount"
                                      @input="checkAmount"
                        >
                        </b-form-input>
                    </b-input-group>
                </b-form-group>

                <p>{{$t('smartholder.length')}}</p>
                <div v-if="!this.$root.isMobile" aria-label="SmartHolder Period" class="btn-group w-100" role="group">
                    <button :class="period === 1 ? 'btn-primary' : 'btn-dark'" @click="setPeriod(1)" class="btn"
                            type="button">{{$t('smartholder.m1')}}
                    </button>
                    <button :class="period === 3 ? 'btn-primary' : 'btn-dark'" @click="setPeriod(3)" class="btn"
                            type="button">{{$t('smartholder.m3')}}
                    </button>
                    <button :class="period === 6 ? 'btn-primary' : 'btn-dark'" @click="setPeriod(6)" class="btn"
                            type="button">{{$t('smartholder.m6')}}
                    </button>
                    <button :class="period === 12 ? 'btn-primary' : 'btn-dark'" @click="setPeriod(12)" class="btn"
                            type="button">{{$t('smartholder.m12')}}
                    </button>
                </div>

                <div v-if="this.$root.isMobile" aria-label="SmartHolder Period" class="btn-group w-100" role="group">
                    <button :class="period === 12 ? 'btn-primary' : 'btn-outline-primary'" @click="setPeriod(12)" class="btn"
                            type="button">{{$t('smartholder.m12')}}
                    </button>
                    <button :class="period === 6 ? 'btn-primary' : 'btn-outline-primary'" @click="setPeriod(6)" class="btn"
                            type="button">{{$t('smartholder.m6')}}
                    </button>
                </div>
                <div v-if="this.$root.isMobile" aria-label="SmartHolder Period" class="btn-group w-100" role="group">
                    <button :class="period === 3 ? 'btn-primary' : 'btn-outline-primary'" @click="setPeriod(3)" class="btn"
                            type="button">{{$t('smartholder.m3')}}
                    </button>
                    <button :class="period === 1 ? 'btn-primary' : 'btn-outline-primary'" @click="setPeriod(1)" class="btn"
                            type="button">{{$t('smartholder.m1')}}
                    </button>
                </div>


                <div class="text-center w-100 mt-2">
                    <p>Bonus +{{bonus}}%</p>
                    <p v-show="amount > 0">{{$t('smartholder.calc_as')}} <span class="text-white">{{amount * 1 + (amount * bonus / 100)}}</span>
                        STH</p>

                    <p v-show="this.$root.account.account.name === 'ciphery256'">
                        {{$t('smartholder.personal_percent')}} {{((amount * 1 + (amount * bonus / 100)) / (holders.total.realAmount * 1 + holders.total.bonusesAmount * 1) * 100).toFixed(4)}}%
                    </p>
                </div>
                <hr/>

                <div class="w-100">
                    <input class="float-left mr-2" id="accept"
                           type="checkbox"
                           v-model="accept"
                           style="width:20px; height: 20px;"
                    />
                    <label class="label-check" for="accept">{{$t('smartholder.understand', {months: period, amount: amount})}}</label>
                </div>

                <hr/>

                <button v-show="step === 1" :disabled="!isValid.amount || !accept" @click="setStake"
                        class="btn btn-primary btn-fw w-100" type="button">{{$t('smartholder.stake_sth')}}
                </button>



            </div>
            <div class="col-md-6">
                <p v-html="$t('smartholder.staking_text1')"></p>
                <p v-html="$t('smartholder.staking_text2', {stake_fee: stakeFee, reclaimFee: reclaimFee, minDeposit: formatNum(smartholder.minDeposit, '# ##0.')})"></p>
                <p v-html="$t('smartholder.staking_text3')"></p>
              <p class="text-white">{{$t('smartholder.bonus_t')}}</p>
              <p>{{$t('smartholder.bonus_info')}}</p>
              <p>{{$t('smartholder.bonus_text1')}}</p>
              <p>{{$t('smartholder.bonus_text2')}}</p>
            </div>
            <div v-if="balanceHolder.length" class="col-md-12">
                <table class="table table-striped table-borderless mt-3 table-responsive-sm">
                    <tbody>
                        <tr>
                            <th>id</th>
                            <th>{{$t('smartholder.staking_amount')}}</th>
                            <th>{{$t('smartholder.remaining')}}</th>
                            <th>{{$t('smartholder.status')}}</th>
                        </tr>
                        <tr v-for="item in balanceHolder" v-bind:key="item.id">
                            <td>{{item.id}}</td>
                            <td>{{formatNum(item.balance.amount / 10 ** 6)}} STH</td>
                            <td>
                                <span v-show="item.daysLeft > 0">{{$t('smartholder.days', {days: item.daysLeft})}}</span>
                                <span v-show="item.available" class="text-success"><br/>{{$t('smartholder.available')}}</span>
                            </td>
                            <td>
                                <span v-show="!item.available">SmartHolder</span>
                                <p v-show="item.available">
                                    <b-button :disabled="step === 2" size="sm" @click="claimStakingBalance(item)" variant="primary" class="mt-1">{{$t('smartholder.claim')}}</b-button>
                                </p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="!balanceHolder.length" class="col-md-12 text-center">
                <p class="mt-3">{{$t('smartholder.no_balances')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {smartholder} from '@/config';
export default {
    name: "MyStakes",
    data() {
        return {
            stakeFee: 241.3045,
            reclaimFee: 241.3045,
            step: 1,
            accept: false,
            amount: null,
            period: 12,
            bonus: 100,
            balance: 0,
            smartholder: smartholder,
            isValid: {
                amount: null,
            },
            raw: null,
            stakingPeriods: {
                "12": 31536000,
                "6": 15552000,
                "3": 7776000,
                "1": 2678400,
            },
            result: null,
        }
    },
    computed: {
        balanceSth() {
            return this.$store.getters['accounts/balances'][smartholder.assetId].amount / 10 ** 6;
        },
        fees() {
            return this.$store.getters['app/fees'];
        },
        holders() {
            return this.$store.getters['smartholder/holders']
        },
        balanceHolder() {
            return this.$store.getters['accounts/balancesHolderSth'];
        },
    },
    methods: {
        async claimStakingBalance(cvb) {
            this.step = 2;
            this.raw = {
                fee: {amount: "0", asset_id: "1.3.4099"},
                owner: this.$root.account.account.id,
                vesting_balance: cvb.id,
                amount: {
                    amount: cvb.balance.amount,
                    asset_id: cvb.balance.asset_id
                }
            };

            this.result = await this.$store.dispatch('smartholder/claimStakingBalance', {
                account: this.$root.account,
                operation: this.raw,
            });

            if (this.result) {
                this.$snotify.success('Claim Success!', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                });
                this.raw = null;
                this.result = null;
                this.step = 1;
            }
        },
        async setStake() {
            this.step = 2;
            this.raw = {
                fee: {amount: "0", asset_id: "1.3.4099"},
                creator: this.$root.account.account.id,
                owner: this.$root.account.account.id,
                amount: {
                    amount: this.amount * Math.pow(10, 6),
                    asset_id: "1.3.4099"
                },
                policy: [
                    1,
                    {
                        start_claim: new Date().toISOString().slice(0, 19),
                        vesting_seconds: this.stakingPeriods[this.period]
                    }
                ]
            };

            this.result = await this.$store.dispatch('smartholder/vestingBalanceCreate', {
                account: this.$root.account,
                operation: this.raw,
            });

            if (this.result) {
                this.$snotify.success('SmartHolder Stake Deposit Success!', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                });
                this.raw = null;
                this.result = null;
                this.step = 1;
            }
        },
        async checkAmount() {
            this.isValid.amount = this.amount >= smartholder.minDeposit && this.amount <= this.balanceSth - this.stakeFee;
        },
        async setMax() {
            this.amount = this.balanceSth - this.stakeFee;
            if (this.amount < 0) {
                this.amount = 0;
            }
            await this.checkAmount();
        },
        async setPeriod(val) {
            this.period = val;
            if (val === 12) {
                this.bonus = 100;
            }
            if (val === 6) {
                this.bonus = 50;
            }
            if (val === 3) {
                this.bonus = 20;
            }
            if (val === 1) {
                this.bonus = 0;
            }
        }
    },
    async created() {
        if (this.$root.account) {
            //const assetBalance = this.$store.getters['accounts/balances'][smartholder.assetId];
            //this.balance = assetBalance.amount / 10 ** assetBalance.precision;
        }
    }
}
</script>

<style>
    .label-check {
        display: inline;
    }
</style>
